import AnimatedLetters from "../AnimatedLetters";
import "./index.scss";
import { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCss3,
  faGitAlt,
  faHtml5,
  faJsSquare,
  faNodeJs,
  faReact,
} from "@fortawesome/free-brands-svg-icons";
import Loader from "react-loaders";
import Timeline from "../TimeLine";

const About = () => {
const [letterClass, setLetterClass] = useState("text-animate");
  useEffect(() => {
    setTimeout(() => {
      setLetterClass("text-animate-hover");
    }, 3000);
  }, []);
  return (
    <>
      <Loader type="pacman" />
      <div className="container about-page">
        <div className="text-zone">
          <h1>
            <AnimatedLetters
              strArray={["A", "b", "o", "u", "t", " ", "M", "e"]}
              idx={15}
              letterClass={letterClass}
            />
          </h1>
          <p>
            I am an enthusiastic web developer who is passionate about creating
            innovative and dynamic websites that engage users and provide a
            seamless online experience.
          </p>
          <p>
            As a creative UI designer, I enjoy experimenting with new design
            ideas and implementing them in visually appealing ways to enhance
            the user's experience.{" "}
          </p>
          <p>
            I possess a profound fondness for the JavaScript programming
            language as well as the React and React Native frameworks. These
            tools enable me to construct sophisticated, interactive, and dynamic
            web and mobile applications with efficacy and expediency.
          </p>
        </div>
        <div className="time-line">
            <Timeline />
          </div>
        <div className="stage-cube-container">
          <div className="cubespinner">
            <div className="face1">
              <FontAwesomeIcon icon={faReact} color="#5ED4F4" />
            </div>
            <div className="face2">
              <FontAwesomeIcon icon={faHtml5} color="#F06529" />
            </div>
            <div className="face3">
              <FontAwesomeIcon icon={faCss3} color="#28A4D9" />
            </div>
            <div className="face4">
              <FontAwesomeIcon icon={faJsSquare} color="#EFD81D" />
            </div>
            <div className="face5">
              <FontAwesomeIcon icon={faGitAlt} color="#EC4D28" />
            </div>
            <div className="face6">
              <FontAwesomeIcon icon={faNodeJs} color="#43853d" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default About;
