import { Link } from "react-router-dom";
import AnimatedLetters from "../AnimatedLetters";
import BackgroundAnimation from "../BackgroundAnimation/BackgroundAnimation";
import "./index.scss";
import { useEffect, useState } from "react";
import Loader from "react-loaders";

const Home = () => {
  const [letterClass, setLetterClass] = useState("text-animate");
  const jobArray = [
    "W",
    "e",
    "b",
    " ",
    "D",
    "e",
    "v",
    "e",
    "l",
    "o",
    "p",
    "e",
    "r",
    ".",
  ];
  useEffect(() => {
    setTimeout(() => {
      setLetterClass("text-animate-hover");
    }, 4000);
  }, []);

  return (
    <>
     <Loader type="pacman" />
      <div className="container home-page">
        <BackgroundAnimation />
        <div className="text-zone">
          <h1>
            Hi, <br /> I'm <span id="name">Amir</span>
            <br />
            <AnimatedLetters
              letterClass={letterClass}
              strArray={jobArray}
              idx={22}
            />
          </h1>
          <h2>Front-end Developer / UI and UX Designer/ Javascript Savvy</h2>
          <Link to="/contact" className="flat-button" id="home-button">
            Contact Me
          </Link>
        </div>
      </div>
    </>
  );
};

export default Home;
