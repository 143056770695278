import { Link, NavLink } from 'react-router-dom';
import '../Sidebar/index.scss';
import LogoS from '../../assets/images/logo-s.png';
import LogoSubtitle from '../../assets/images/logo_sub.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faHome, faUser } from '@fortawesome/free-solid-svg-icons';
import { faGithub, faInstagram, faLinkedin, faTwitter } from '@fortawesome/free-brands-svg-icons';

const Sidebar = () => (
    <div className="nav-bar">
        <Link className='logo' to ='/'>
            <img id='profile-pic' src={LogoS} alt="logo" />
            <img src={LogoSubtitle} alt="logo" />
        </Link>
        <nav>
            <NavLink exact="true" activeClassName="active" to='/'>
                <FontAwesomeIcon icon={faHome} color="#4d4d4e" />
            </NavLink>
            <NavLink className="about-link" exact="true" activeClassName="active" to='/about'>
                <FontAwesomeIcon icon={faUser} color="#4d4d4e" />
            </NavLink>
            <NavLink className="contact-link" exact="true" activeClassName="active" to='/contact'>
                <FontAwesomeIcon icon={faEnvelope} color="#4d4d4e" />
            </NavLink>
        </nav>
        <ul>
            <li>
                <a href="https://www.linkedin.com/in/amirmsvi" target="_blank" rel='noreferrer' > 
                    <FontAwesomeIcon icon={faLinkedin} color="#4d4d4e" /> 
                </a>
            </li>
            <li>
                <a href="https://www.instagram.com/amir_msvi" target="_blank" rel='noreferrer' > 
                    <FontAwesomeIcon icon={faInstagram} color="#4d4d4e" />
                </a>
            </li>
            <li>
                <a href="https://www.twitter.com/amirmsvi" target="_blank" rel='noreferrer' > 
                    <FontAwesomeIcon icon={faTwitter} color="#4d4d4e" />  
                </a>
            </li>
            <li>
                <a href="https://www.github.com/amirmsvi" target="_blank" rel='noreferrer' > 
                    <FontAwesomeIcon icon={faGithub} color="#4d4d4e" /> 
                </a>
            </li>
        </ul>

    </div>

)
export default Sidebar;