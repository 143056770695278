import { Chrono } from "react-chrono";

const items = [
  {
    title: "September 2014",
    cardTitle: "BSc. Computer Engineering",
    cardSubtitle: "software engineering",
    cardDetailedText: "Started my studies at the Azad University.",
  },
  {
    title: "February 2020",
    cardTitle: "RheinHome Smart home Startup",
    cardSubtitle: "Frontend Developer/ UI Designer/ Project Manager",
    cardDetailedText:
      "Implementing the frontend of the RheinHome app which was built with React Native And was the application of IoT smart devices.",
  },
  {
    title: "September 2021",
    cardTitle: "MBA in Digital Business",
    cardSubtitle: "Studying at the University of Tehran",
    cardDetailedText:
      "Improved my skills in the field of digital business and entrepreneurship.",
  },
  {
    title: "June 2022",
    cardTitle: "Web Developer",
    cardSubtitle: "Studying at the Tehran Institute of Technology",
    cardDetailedText:
      "Improved my skills in the field of web development and programming.",
  },
  {
    title: "January 2023",
    cardTitle: "MEng in Computer Engineering",
    cardSubtitle: "Studying at the University of Windsor",
    cardDetailedText:
      "pursuing a master's degree in computer engineering at the University of Windsor.",
  },
];

const Timeline = () => {
  return (
    <Chrono
      items={items}
      timelinePointShape="circle"
      mode="HORIZONTAL"
      itemWidth={150}
      slideItemDuration={5000}
      slideshow
    />
  );
};

export default Timeline;
